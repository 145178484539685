"use client"

import Hamburger from "@ui-library/header/navigation/Hamburger"
import { cn } from "@utils/utils"
import LanguageSelector from "./LanguageSelector"

type HeaderUtilsProps = {
  children?: React.ReactNode
}

const HeaderUtils = ({ children }: HeaderUtilsProps) => {
  return (
    <div
      className={cn(
        "flex items-center xl:absolute xl:right-4 xl:top-2/4 xl:-translate-y-2/4 max-sm:[&>.button]:px-4 max-sm:[&>.button]:py-1"
      )}
    >
      <LanguageSelector />
      {children}
      <Hamburger />
    </div>
  )
}

export default HeaderUtils
