"use client"

import DoktorIcon from "@/layout/DoktorIcons"
import ChevronDownIcon from "@/layout/icons/ChevronDownIcon"
import { useConfigStore } from "@/store/config-store"
import { ConfigStoryblok } from "@/types/component-types"
import { cn } from "@/utils/utils"
import { Button } from "@ui-library/button/Button"
import { Label } from "@ui-library/typography/Labels"
import { useEffect, useState } from "react"

import { useFloatingCta } from "@/store/floating-cta-store"
import { Paragraph } from "@ui-library/typography/Body"
import Image from "next/image"

export const FloatingCTADesktop = () => {
  const config = useConfigStore.getState().config as ConfigStoryblok
  const { enabledDesktop } = useFloatingCta()
  const [minified, setMinified] = useState(true)

  const label = config?.desktop_default_floating_cta_label

  const handleMinify = () => {
    setMinified(true)
    localStorage.setItem("desktop_floating_cta_minified", "true")
  }

  const handleMaximize = () => {
    setMinified(false)
    localStorage.removeItem("desktop_floating_cta_minified")
  }

  useEffect(() => {
    const isMinified = localStorage.getItem("desktop_floating_cta_minified")
    if (!isMinified) setMinified(false)
  }, [])

  if (!enabledDesktop || !label) return null

  return (
    <div className={cn("pointer-events-none flex w-full justify-end px-4 max-xl:hidden", minified && "pb-4")}>
      <figure
        className={cn(
          "pointer-events-auto w-full max-w-56 rounded-t-2xl bg-accent p-4 text-center shadow-elevation-1",
          minified && "flex h-14 items-center rounded-full p-2"
        )}
      >
        <button onClick={handleMinify} className={cn("inline-flex w-full justify-end", minified && "hidden")}>
          <ChevronDownIcon />
        </button>
        <Button variant="ghost" className={cn("w-full no-underline", !minified && "mb-4 p-0")} onClick={handleMaximize}>
          <Label size="medium" className="pointer-events-none">
            {config.desktop_default_floating_cta_label}
          </Label>
          <DoktorIcon
            iconName={config.desktop_default_floating_cta_icon || "LightEmergency"}
            width={22}
            height={22}
            className={cn("pointer-events-none ml-2", !minified && "hidden")}
          />
        </Button>

        {config?.desktop_floating_cta_qr_code && (
          <figure className={cn("mb-4 inline-flex rounded-lg bg-surface p-4", minified && "hidden")}>
            <Image
              src={config?.desktop_floating_cta_qr_code?.filename}
              alt={config?.desktop_floating_cta_qr_code?.alt || ""}
              width={120}
              height={120}
            />
          </figure>
        )}

        {config?.desktop_floating_cta_content && (
          <Paragraph size="small" className={cn(minified && "hidden")}>
            {config?.desktop_floating_cta_content}
          </Paragraph>
        )}
      </figure>
    </div>
  )
}
