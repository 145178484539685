"use client"

import { urlParser } from "@/lib/urlParser"
import { cn } from "@utils/utils"
import Image from "next/image"
import Link from "next/link"

interface HeaderLogoProps {
  filename: string
  alt?: string
  href?: string
  largeLogotype?: boolean
}

const HeaderLogo = ({ filename, alt, href, largeLogotype }: HeaderLogoProps) => {
  const homeUrl = urlParser(href)

  return (
    <Link
      href={homeUrl}
      className={cn(
        "block xl:absolute xl:left-4 xl:top-2/4 xl:-translate-y-2/4",
        largeLogotype && "h-6 xl:h-8",
        !largeLogotype && "h-5 xl:h-6"
      )}
    >
      <Image src={filename || ""} width={144} height={50} alt={alt || ""} className="h-full w-auto" />
      <span className="sr-only">Doktor.se</span>
    </Link>
  )
}

export default HeaderLogo
