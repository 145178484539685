"use client"

import { useRef } from "react"
import { ConfigStoreProps, useConfigStore } from "./config-store"

export const ConfigStoreInitializer = ({ config, strings, en }: ConfigStoreProps) => {
  const initialized = useRef(false)

  if (!initialized.current) {
    useConfigStore.setState({ config: config, strings: strings, en: en })
    initialized.current = true
  }

  return null
}
