"use client"

import { MultilinkStoryblok } from "@storyblok-types"
import HeaderLogo from "@ui-library/header/HeaderLogo"
import { useEffect, useState } from "react"
import HeaderUtils from "./HeaderUtils"
import Navigation from "./navigation/Navigation"

import { useHover } from "@/hooks/useHover"
import { usePreventScroll } from "@/hooks/usePreventScroll"
import { useScrollDetection } from "@/hooks/useScrollDetection"
import { useWindowResize } from "@/hooks/useWindowResize"
import { useConfigStore } from "@/store/config-store"
import { useHeader } from "@/store/header-store"
import { cn } from "@utils/utils"
import BackToDoktor from "./BackToDoktor"
import HeaderCTA from "./HeaderCTA"

const Header = ({ isClinicPage, isEnglishWebsite }: { isClinicPage: boolean; isEnglishWebsite: boolean }) => {
  const config = useConfigStore.getState().config
  const header_link = config?.link ? (config.link as MultilinkStoryblok) : null
  const hideBackLink = (config?.hide_back_to_doktor as boolean) ?? true
  const { enableHover } = useHover()
  const { isOpen, onClose } = useHeader()
  const [enableFocus, setEnableFocus] = useState<boolean>(false)

  const handleFocusKeyboardNavigation = (e: React.KeyboardEvent) => {
    if (e.key === "Tab") {
      setEnableFocus(true)
    } else {
      setEnableFocus(false)
    }
  }

  const handleWindowResize = () => {
    if (typeof window !== "undefined") {
      if (window.innerWidth >= 1024) {
        useHeader.setState({ isOpen: false })
      }
    }
  }

  useEffect(() => {
    handleWindowResize()
  }, [])

  const { isHidden, isSticky } = useScrollDetection()

  useWindowResize(handleWindowResize)
  usePreventScroll(isOpen)

  const homeUrl = isEnglishWebsite ? "en" : "/"

  return (
    <header
      className={cn(
        "fixed z-[9999] w-full bg-background transition-all max-xl:py-2 xl:-mb-2",
        isClinicPage && hideBackLink && "max-xl:py-10 max-xl:pb-2",
        isClinicPage && !hideBackLink && "max-xl:py-10 max-xl:pb-2",
        isSticky && !isHidden && "shadow-elevation-1",
        isHidden && isSticky && !isOpen && "-translate-y-full"
      )}
      onKeyDown={handleFocusKeyboardNavigation}
    >
      {!hideBackLink && <BackToDoktor />}

      <div className="relative flex w-full items-center justify-between pl-4 pr-2 xl:justify-center xl:px-4">
        <HeaderLogo
          filename={config?.header_logotype.filename || ""}
          alt={config?.header_logotype.alt}
          href={header_link?.cached_url || homeUrl}
          largeLogotype={isClinicPage}
        />

        <Navigation
          items={config?.header_menu}
          footer_privacy_menu={config?.footer_privacy_menu}
          enableHover={enableHover}
          enableFocus={enableFocus}
          onClose={onClose}
        />

        <HeaderUtils>
          <HeaderCTA buttons={config?.header_button_link} />
        </HeaderUtils>

        <figure
          onClick={onClose}
          className={cn(
            "invisible fixed inset-0 h-full w-full bg-black opacity-0 backdrop-blur-none transition-[opacity,_visibility,_backdrop-filter]",
            isOpen && "max-xl:visible max-xl:opacity-60"
          )}
        />
      </div>
    </header>
  )
}

export default Header
