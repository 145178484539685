import React, { useEffect, useState } from "react"

interface DoktorIconProps {
  iconName: string
  width?: number
  height?: number
  className?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  iconProps?: Record<string, any>
}

export const DoktorIcon: React.FC<DoktorIconProps> = ({ iconName, width = 16, height = 16, className, iconProps }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [icon, setIcon] = useState<React.FC<any> | null>(null)

  useEffect(() => {
    // Dynamically import the icon component as a function
    import(`./doktor-icons/icons/${iconName}.tsx`)
      .then(iconModule => {
        setIcon(() => iconModule.default) // Wrap the component in a function
      })
      .catch((error: Error) => {
        console.error(`Error loading icon: ${iconName}`, error)
      })
  }, [iconName])

  if (!icon) return null

  const IconComponent = icon

  return <IconComponent width={width} height={height} className={className} {...iconProps} />
}

export default DoktorIcon
