import { create } from "zustand"

type FloatingCtaStore = {
  enabledMobile: boolean
  enabledDesktop: boolean
  icon?: string
  label?: string
  event?: string
  href?: string
}

export const useFloatingCta = create<FloatingCtaStore>(() => ({
  enabledMobile: true,
  enabledDesktop: true,
  icon: undefined,
  label: undefined,
  event: undefined,
  href: undefined
}))
