import { useEffect, useState } from "react"

export const useHover = () => {
  const [enableHover, setEnableHover] = useState<boolean>(false)

  const handleWindowResize = () => {
    if (typeof window !== "undefined") {
      setEnableHover(window.innerWidth >= 1024)
    }
  }

  useEffect(() => {
    handleWindowResize()
    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  return { enableHover }
}
