import { useEffect, useState } from "react"

export const useScrollDetection = () => {
  const [isSticky, setIsSticky] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const [lastScrollTop, setLastScrollTop] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY
      const isScrollingDown = currentScrollPos > lastScrollTop
      const scrollDiff = Math.abs(currentScrollPos - lastScrollTop)

      if (currentScrollPos >= 100) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }

      if (scrollDiff > 100) {
        setIsHidden(isScrollingDown)
        setLastScrollTop(currentScrollPos)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [lastScrollTop])

  return { isSticky, isHidden }
}
