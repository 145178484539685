"use client"

import DoktorIcon from "@/layout/DoktorIcons"
import { trackEvent } from "@/lib/amplitude/amplitude"
import { truncateString } from "@/lib/truncateString"
import { urlParser } from "@/lib/urlParser"
import { useFloatingCta } from "@/store/floating-cta-store"
import { cn } from "@/utils/utils"
import { Button } from "@ui-library/button/Button"
import Hamburger from "@ui-library/header/navigation/Hamburger"
import { Label } from "@ui-library/typography/Labels"
import Link from "next/link"
import { usePathname } from "next/navigation"

export const FloatingCTAMobile = () => {
  const pathname = usePathname()
  const { enabledMobile, icon, label, event, href } = useFloatingCta()
  const handleClick = () => {
    if (event) trackEvent(event, { page_path: pathname })
  }

  let linkTarget = "_self"
  if (href) {
    const isOnelink = href.includes("onelink")
    if (isOnelink) linkTarget = "_top"
  }

  if (!enabledMobile || !label) return null

  return (
    <div className={cn("flex w-full justify-center gap-2 p-4 md:justify-end xl:hidden")}>
      <Button
        variant="accent"
        asChild
        className="pointer-events-auto flex h-14 max-w-floatingCta items-center justify-center px-12 py-2 shadow-elevation-1 max-md:grow"
      >
        <Link href={urlParser(href)} target={linkTarget} onClick={handleClick}>
          <Label size="medium" className="pointer-events-none mr-2 text-ellipsis">
            {truncateString(label, 20)}
          </Label>
          <DoktorIcon
            iconName={icon ? icon : "LightEmergency"}
            width={22}
            height={22}
            className="pointer-events-none"
          />
        </Link>
      </Button>

      <Hamburger large className="h-14 w-14 rounded-full bg-background shadow-elevation-1 md:hidden" />
    </div>
  )
}
