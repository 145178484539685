"use client"

import { useScrollDetection } from "@/hooks/useScrollDetection"
import { useConfigStore } from "@/store/config-store"
import { useFloatingCta } from "@/store/floating-cta-store"
import { useListingStore } from "@/store/listing-store"
import { useStoryStore } from "@/store/story-store"
import { cn } from "@/utils/utils"
import { FloatingCTADesktop } from "@ui-library/floating-cta/FloatingCTADesktop"
import { FloatingCTAMobile } from "@ui-library/floating-cta/FloatingCTAMobile"
import { useEffect } from "react"

export const CTAProvider = () => {
  const config = useConfigStore.getState().config
  const story = useStoryStore.getState().story || useListingStore.getState().story
  const { isHidden: isVisible, isSticky } = useScrollDetection()

  useEffect(() => {
    useFloatingCta.setState({
      icon: config?.default_floating_cta_icon,
      label: config?.default_floating_cta_label,
      event: config?.default_floating_cta_custom_event || config?.default_floating_cta_event,
      href: config?.default_floating_cta_link?.cached_url
    })

    if (config?.disable_floating_cta) {
      const disabledOn = config?.disable_floating_cta.split(",")
      disabledOn.map((slug: string) => {
        if (story?.full_slug.includes(slug)) {
          useFloatingCta.setState({ enabledMobile: false, enabledDesktop: false })
          return
        }
      })
    }

    if (story?.content.custom_floating_cta) {
      useFloatingCta.setState({
        icon: story?.content.floating_cta_icon,
        label: story?.content.floating_cta_label,
        event: story?.content.floating_cta_custom_event || story?.content.floating_cta_event,
        href: story?.content.floating_cta_link?.cached_url
      })
      return
    }

    if (config?.floating_ctas && config.floating_ctas.length > 0) {
      config?.floating_ctas?.map(cta => {
        if (!cta.enable_on) return

        const enableOn = cta.enable_on.split(",")
        enableOn.map(slug => {
          if (story?.full_slug.includes(slug)) {
            useFloatingCta.setState({
              icon: cta.cta_icon,
              label: cta.cta_label,
              event: cta.cta_custom_event || cta.cta_event,
              href: cta.cta_link?.cached_url
            })
            return
          }
        })
      })
    }

    if (config?.disable_floating_cta_everywhere_mobile) {
      useFloatingCta.setState({ enabledMobile: false })
    }

    if (config?.disable_floating_cta_everywhere_desktop) {
      useFloatingCta.setState({ enabledDesktop: false })
    }

    if (story?.content.disable_floating_cta) {
      useFloatingCta.setState({ enabledMobile: false, enabledDesktop: false })
    }
  }, [config, story])

  return (
    <nav
      className={cn(
        "fixed bottom-0 z-50 flex w-full translate-y-full transition-transform lg:pointer-events-none",
        isVisible && isSticky && "translate-y-0"
      )}
    >
      <FloatingCTADesktop />
      <FloatingCTAMobile />
    </nav>
  )
}

export default CTAProvider
