import ChevronLeftIcon from "@layout/icons/ChevronLeftIcon"
import { Paragraph } from "@ui-library/typography/Body"
import { cn } from "@utils/utils"
import Link from "next/link"

const BackToDoktor = ({ className }: { className?: string }) => {
  return (
    <div className={cn("flex h-8 w-full items-center bg-primary-50 px-4", className)}>
      <Link href="/" className="inline-flex items-center justify-start text-surface">
        <ChevronLeftIcon width={11} height={18} />
        <Paragraph size="small" className="ml-4" asChild>
          <span>Till startsidan</span>
        </Paragraph>
      </Link>
    </div>
  )
}

export default BackToDoktor
