import { trackEvent } from "@/lib/amplitude/amplitude"
import { clinicPageNavigationListingEvent, mainNavigationListingEvent } from "@/lib/amplitude/helpers"
import { urlParser } from "@/lib/urlParser"
import { useConfigStore } from "@/store/config-store"
import { ClinicConfigStoryblok, ConfigStoryblok } from "@/types/component-types"
import { Button } from "@ui-library/button/Button"
import Link from "next/link"
import { usePathname } from "next/navigation"

interface HeaderCTAProps {
  buttons: ConfigStoryblok["header_button_link"]
}

type CTA = {
  href: string
  target: string
  text: string
  event: () => void
}

const HeaderCTA = ({ buttons }: HeaderCTAProps) => {
  const pathname = usePathname()
  if (buttons?.length === 0) return null

  let href
  let text
  let event
  let target
  const variant = buttons ? buttons[0].variant : "primary"

  if (
    pathname === "/vardcentraler" ||
    pathname === "/sa-fungerar-listning" ||
    pathname === "/bvc" ||
    pathname === "/prickmottagning" ||
    pathname === "/vaccination-pa-mottagning" ||
    pathname === "/vaccinationsplats" ||
    pathname === "/lattakut"
  ) {
    href = "/lista-dig"
    text = "Lista dig"
    event = () => mainNavigationListingEvent(pathname)
  } else if (pathname.includes("vardcentraler/")) {
    const config = useConfigStore.getState().config as ClinicConfigStoryblok
    const enabledRegions = useConfigStore.getState().enabledRegions

    text = "Lista dig här"
    event = () => clinicPageNavigationListingEvent(config?.clinic_name || "", pathname)

    if (config.clinic_region_id && enabledRegions) {
      if (!enabledRegions?.includes(config.clinic_region_id)) {
        const listingTypeCode = config.clinic_region_id === "01" ? "PV" : "HLM"
        const listingPageUrl = `https://listning.1177.se/mottagning/${config.clinic_hsa_id}?listingTypeCode=${listingTypeCode}&countyCode=${config.clinic_region_id}`
        href = listingPageUrl
        target = "_blank"
      } else {
        const listingPageUrl = config?.clinic_api_id ? `/lista-dig?clinicId=${config?.clinic_api_id}` : "/lista-dig"
        href = listingPageUrl
      }
    }
  } else {
    href = buttons ? buttons[0].link.cached_url : ""
    text = buttons ? buttons[0].name : ""
    event = () => trackEvent(buttons ? (buttons[0].event as string) : "", { page_path: pathname })
  }

  const ctaTarget = target ? target : href?.includes("onelink") ? "_top" : "_self"

  const cta: CTA = {
    href: urlParser(href),
    target: ctaTarget,
    text: text,
    event: event
  }

  return (
    <Button variant={variant} size="custom" asChild>
      <Link href={cta.href} target={cta.target} onClick={cta.event}>
        {cta.text}
      </Link>
    </Button>
  )
}

export default HeaderCTA
