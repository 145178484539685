"use client"

import { useHeader } from "@/store/header-store"
import { cn } from "@utils/utils"

const Hamburger = ({ className, large }: { className?: string; large?: boolean }) => {
  const { isOpen, toggle } = useHeader()

  return (
    <button
      onClick={toggle}
      className={cn("relative z-50 ml-2 block h-12 w-12 xl:hidden", className)}
      aria-label="Öppna & Stäng meny"
    >
      <span
        className={cn(
          "absolute left-3 top-2/4 block h-[3px] w-6 -translate-y-2 rounded-full bg-on-surface transition-transform",
          large && "left-4",
          isOpen && "translate-y-0 rotate-45"
        )}
      />
      <span
        className={cn(
          "absolute left-3 top-2/4 block h-[3px] w-6 rounded-full bg-on-surface transition-colors",
          large && "left-4",
          isOpen && "bg-transparent"
        )}
      />
      <span
        className={cn(
          "absolute left-3 top-2/4 block h-[3px] w-6 translate-y-2 rounded-full bg-on-surface transition-transform",
          large && "left-4",
          isOpen && "translate-y-0 -rotate-45"
        )}
      />
    </button>
  )
}

export default Hamburger
