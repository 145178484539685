"use client"

import LanguageIcon from "@/layout/icons/LanguageIcon"
import { cn } from "@/utils/utils"
import { Label } from "@ui-library/typography/Labels"
import Link from "next/link"
import { useState } from "react"

const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const items = [
    { url: "/", label: "Svenska" },
    { url: "/en", label: "English" }
  ]

  return (
    <div className="relative block max-xl:hidden">
      <button onClick={() => setIsOpen(!isOpen)} className="h-[72px] w-14">
        <LanguageIcon />
      </button>

      <div
        aria-hidden={!isOpen}
        role="menu"
        className={cn(
          "absolute left-2/4 top-full -translate-x-2/4 pt-4 transition-[opacity,_transform] duration-300",
          !isOpen && "pointer-events-none translate-y-6 opacity-0",
          isOpen && "pointer-events-auto translate-y-0 opacity-100"
        )}
      >
        <div className={cn("relative w-full rounded-lg bg-surface drop-shadow-lg")}>
          <span className="absolute -top-1 left-2/4 -ml-2 block h-4 w-4 rotate-45 bg-surface" />

          <ul className="min-w-[160px] p-4">
            {items.map((item, i) => (
              <li key={i} className="m-2">
                <Label size="small" asChild>
                  <Link
                    href={item.url}
                    target="_top"
                    className="block cursor-pointer text-on-background transition-colors hover:text-primary-50"
                  >
                    {item.label}
                  </Link>
                </Label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default LanguageSelector
